import React, { useEffect, useState } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { setLanguageBasedOnLocation } from './languageUtils';
import logo from './logo.png';
import './App.css';

function App() {
	const { t, i18n } = useTranslation();
	const [activeTab, setActiveTab] = useState<string>('info');

	useEffect(() => {
		// Check if a language is stored in local storage
		const storedLanguage = localStorage.getItem('language');
		if (storedLanguage) {
			i18n.changeLanguage(storedLanguage);
		} else {
			// If no language is stored, set language based on location
			setLanguageBasedOnLocation();
		}
	}, [i18n]);

	const handleTabClick = (tab: string) => {
		setActiveTab(tab);
	};

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('language', lng); // Store the selected language
	};

	return (
		<div className='App'>
			<div className='container'>
				{/* Language Selection */}
				<div className='language-switcher'>
					<button onClick={() => changeLanguage('en')}>🇬🇧 English</button>
					<button onClick={() => changeLanguage('pl')}>🇵🇱 Polski</button>
				</div>

				<nav className='tabs'>
					{['info', 'mods', 'update'].map(tab => (
						<button
							key={tab}
							className={activeTab === tab ? 'active' : ''}
							onClick={() => handleTabClick(tab)}
						>
							{t(tab)}
						</button>
					))}
				</nav>
				<main className='content'>
					<header>
						<div>
							<img src={logo} className='logo' alt='logo' />
							<h1>🌍 {t('valheimApocalypse')}</h1>
						</div>
						{activeTab === 'info' && (
							<section>
								<InfoTab />
							</section>
						)}
					</header>
					{activeTab === 'mods' && (
						<section>
							<ModsTab />
						</section>
					)}
					{activeTab === 'update' && (
						<section>
							<UpdateTab />
						</section>
					)}
				</main>
			</div>
		</div>
	);
}

const InfoTab = () => {
	const { t } = useTranslation();

	return (
		<>
			<table className='table-info'>
				<tbody>
					<tr>
						<td>
							<strong>{t('serverIP')}</strong>
						</td>
						<td>nyupyu.pl</td>
					</tr>
					<tr>
						<td>
							<strong>{t('port')}</strong>
						</td>
						<td>2456</td>
					</tr>
					<tr>
						<td>
							<strong>{t('password')}</strong>
						</td>
						<td>secretPassword</td>
					</tr>
				</tbody>
			</table>
			<div className='info-content'>
				<h3 className='info-warning'>{t('betaTesting')}</h3>
				<p>
					{t('joinCommunity')}{' '}
					<a
						href='https://discord.com/invite/rWWntDTkU4'
						target='_blank'
						rel='noreferrer'
					>
						Discord
					</a>
				</p>
				<p>
					<strong>{t('connectFastLinks')}</strong>
				</p>
				<p>
					💡 <em>{t('manualConnection')}:</em> {t('usePassword')}
					<span className='password-highlight'> secretPassword</span>
				</p>
				<p className='mod-details'>{t('enrichedServer')}</p>
				<p className='mod-info'>{t('detailedInfo')}</p>
				<p className='install-info'>{t('rememberInstall')}</p>
			</div>
		</>
	);
};

const ModsTab = () => {
	const { t } = useTranslation();
	const [codeText, setCodeText] = useState('');
	useEffect(() => {
		// Fetch the code from the config file
		fetch('./code.json')
			.then(response => response.json())
			.then(data => setCodeText(data.codeText))
			.catch(error => console.error('Error fetching code:', error));
	}, []);

	return (
		<>
			<h3>🛠️ {t('mods')}</h3>
			<p>🧩 {t('enrichedServer')}</p>
			<p>📜 {t('detailedInfo')}</p>
			<p>⚠️ {t('rememberInstall')}</p>
			<h4>📥 {t('howToInstallMods')}</h4>
			<ol>
				<li>
					📥 {t('downloadInstall')}{' '}
					<a href='https://r2modman.net/' target='_blank' rel='noreferrer'>
						r2modman launcher
					</a>
				</li>
				<li>🔍 {t('openR2modman')}</li>
				<li>🔎 {t('findValheim')}</li>
				<li>
					🔄{' '}
					<Trans i18nKey='importProfile' values={{ code: codeText }}>
						<a
							href='#'
							onClick={e => {
								e.preventDefault();
								copyToClipboard(codeText);
							}}
							style={{
								color: '#FFF00F',
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
						>
							{codeText}
						</a>
					</Trans>
				</li>
				<li>➕ {t('addReplaceProfile')}</li>
				<li>✅ {t('selectProfile')}</li>
				<li>⏳ {t('waitDownload')}</li>
				<li>🚀 {t('startGame')}</li>
				<li>⏱️ {t('waitFirstRun')}</li>
			</ol>
		</>
	);
};

const copyToClipboard = (text: string) => {
	navigator.clipboard.writeText(text).then(
		() => {
			alert('Code copied to clipboard!');
		},
		err => {
			console.error('Could not copy text: ', err);
		},
	);
};

const UpdateTab = () => {
	const { t } = useTranslation();
	const [codeText, setCodeText] = useState('');
	useEffect(() => {
		// Fetch the code from the config file
		fetch('./code.json')
			.then(response => response.json())
			.then(data => setCodeText(data.codeText))
			.catch(error => console.error('Error fetching code:', error));
	}, []);
	return (
		<>
			<h3>🔄 {t('update')}</h3>
			<ol>
				<li>⚙️ {t('openSettings')}</li>
				<li>📂 {t('selectProfileTab')}</li>
				<li>
					🔄{' '}
					<Trans i18nKey='clickImportUpdate' values={{ code: codeText }}>
						<a
							href='#'
							onClick={e => {
								e.preventDefault();
								copyToClipboard(codeText);
							}}
							style={{
								color: '#FFF00F',
								textDecoration: 'underline',
								cursor: 'pointer',
							}}
						>
							{codeText}
						</a>
					</Trans>
				</li>
				<li>✅ {t('selectProfileUpdate')}</li>
				<li>🎉 {t('done')}</li>
			</ol>
		</>
	);
};

export default App;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: {
				info: 'Info',
				mods: 'How to Install Mods',
				update: 'How to Update Modpack',
				serverIP: 'Server IP:',
				port: 'Port:',
				password: 'Password:',
				betaTesting: 'Server is in Beta Testing',
				joinCommunity:
					'Join our amazing community on Discord for access to exclusive content and updates!',
				connectFastLinks:
					'Effortlessly connect with the FastLinks mod: choose the server at the top-right corner, create a new character, and jump into the action!',
				manualConnection: 'Manual Connection',
				usePassword: 'Use the server password',
				enrichedServer:
					'Our server is enriched with over 90 mods, applied using r2modman to ensure a seamless gaming experience. This comprehensive mod pack, curated by nyupyu, is aimed at delivering the best Valheim adventure possible.',
				detailedInfo:
					'For detailed information about each mod, explore the r2modman launcher. Here, you can view descriptions, features, and updates for all the mods included in our pack.',
				rememberInstall:
					'Remember: To install the mods correctly, use the specific code provided in the "How to Install Mods" and "How to Update Modpack" tabs.',
				downloadInstall: 'Download and install',
				openR2modman: 'Open r2modman',
				findValheim:
					"In the games menu, find Valheim, click 'Set as default', and choose the Steam platform",
				importProfile: 'Import profile from the code <0>{{code}}</0>',
				addReplaceProfile: 'and add or replace the existing "Default" profile',
				selectProfile: 'Select profile',
				waitDownload: 'Wait to download all mods',
				startGame:
					'Start the game directly in r2modman (top left corner, "start modded")',
				waitFirstRun:
					'Wait a few minutes during the first run, as it might freeze with a "Program not responding" error. Just ignore it and wait.',
				openSettings:
					'Open r2modman, select Valheim, then select the profile, and click the Settings button at the bottom left corner',
				selectProfileTab: 'Select the Profile Tab, then Change Profile',
				clickImportUpdate:
					'Click Import/Update > Update existing profile > From code >, and paste <0>{{code}}</0>',
				selectProfileUpdate: 'Select profile to update',
				done: "And it's done :)",
				howToInstallMods: 'How to Install Mods',
			},
		},
		pl: {
			translation: {
				info: 'Informacje',
				mods: 'Jak zainstalować mody',
				update: 'Jak zaktualizować paczkę modów',
				serverIP: 'Adres serwera:',
				port: 'Port:',
				password: 'Hasło:',
				betaTesting: 'Serwer jest w fazie testów beta',
				joinCommunity:
					'Dołącz do naszej niesamowitej społeczności na Discordzie, aby uzyskać dostęp do ekskluzywnych treści i aktualizacji!',
				connectFastLinks:
					'Łatwo połącz się z modem FastLinks: wybierz serwer w prawym górnym rogu, stwórz nową postać i wskocz do akcji!',
				manualConnection: 'Połączenie ręczne',
				usePassword: 'Użyj hasła serwera',
				enrichedServer:
					'Nasz serwer jest wzbogacony o ponad 90 modów, zastosowanych za pomocą r2modman, aby zapewnić płynne doświadczenie w grze. Ta kompleksowa paczka modów, stworzona przez nyupyu, ma na celu dostarczenie najlepszej możliwej przygody w Valheim.',
				detailedInfo:
					'Aby uzyskać szczegółowe informacje o każdym modzie, zapoznaj się z launcherem r2modman. Tutaj możesz zobaczyć opisy, funkcje i aktualizacje wszystkich modów zawartych w naszej paczce.',
				rememberInstall:
					'Pamiętaj: Aby poprawnie zainstalować mody, użyj specjalnego kodu podanego w zakładkach "Jak zainstalować mody" i "Jak zaktualizować paczkę modów".',
				downloadInstall: 'Pobierz i zainstaluj',
				openR2modman: 'Otwórz r2modman',
				findValheim:
					"W menu gier znajdź Valheim, kliknij 'Ustaw jako domyślną' i wybierz platformę Steam",
				importProfile: 'Importuj profil z kodu <0>{{code}}</0>',
				addReplaceProfile: 'i dodaj lub zastąp istniejący profil "Domyślny"',
				selectProfile: 'Wybierz profil',
				waitDownload: 'Poczekaj na pobranie wszystkich modów',
				startGame:
					'Uruchom grę bezpośrednio w r2modman (lewy górny róg, "uruchom z modami")',
				waitFirstRun:
					'Poczekaj kilka minut podczas pierwszego uruchomienia, ponieważ może się zawiesić z błędem "Program nie odpowiada". Po prostu zignoruj to i poczekaj.',
				openSettings:
					'Otwórz r2modman, wybierz Valheim, następnie wybierz profil i kliknij przycisk Ustawienia w lewym dolnym rogu',
				selectProfileTab: 'Wybierz zakładkę Profil, następnie Zmień profil',
				clickImportUpdate:
					'Kliknij Importuj/Aktualizuj > Aktualizuj istniejący profil > Z kodu > i wklej <0>{{code}}</0>',
				selectProfileUpdate: 'Wybierz profil do aktualizacji',
				done: 'I gotowe :)',
				howToInstallMods: 'Jak zainstalować mody',
			},
		},
	},
	lng: 'en',
	fallbackLng: 'en',

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;

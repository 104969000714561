// languageUtils.js
import i18n from './i18n';

export async function setLanguageBasedOnLocation() {
	try {
		const response = await fetch('https://ipapi.co/json/');
		const data = await response.json();
		const countryCode = data.country_code;

		const languageMap = {
			PL: 'pl',
			US: 'en',
		};

		const language = languageMap[countryCode] || 'en';
		i18n.changeLanguage(language);
	} catch (error) {
		console.error('Error fetching location:', error);
		i18n.changeLanguage('en');
	}
}
